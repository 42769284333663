import React from "react"
import styled from "styled-components"

import ScotomalabLogo from "../images/scotomalabwhite.svg"

import Img from "gatsby-image"

import Footer from "./footer"

const Container = styled.div``

const Spacer = styled.div`
  height: 5rem;
  width: 100%;
`

const LogoContainer = styled.div`
  box-sizing: border-box;
  padding: 6rem 1rem 2rem 1rem;

  img {
    width: 100%;
    height: auto;
  }
`

const StyledImg = styled(Img)`
  border-radius: 10px;
  margin: 10px 10px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
`

class GridMobile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <Container>
        {/* <Spacer /> */}
        <LogoContainer>
          {" "}
          <img src={ScotomalabLogo} />
        </LogoContainer>
        {this.props.data.images.map((image, index) => (
          <StyledImg
            key={index}
            fluid={image.image.localFile.childImageSharp.fluid}
          />
        ))}
        <Footer />
      </Container>
    )
  }
}

export default GridMobile
