import React, { useEffect, useState } from "react"
import styled from "styled-components"

const Container = styled.div`
  position: fixed;
  height: 50px;
  width: 100px;
  bottom: 0;
  right: 0;
  overflow: hidden;
  cursor: grab;
  margin: 25px;
  border-radius: 5px;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.3);
`

const LocationIndicator = styled.div`
  position: absolute;
  background-color: white;
  height: 5px;
  width: 5px;
  border-radius: 50px;

  transition-duration: 1s;
`

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
`

const Item = styled.div`
  position: absolute;
  background-color: white;
  width: 10px;
  height: 10px;
`

const Info = styled.div``

const OverviewGrid = props => {
  let width = 100
  let height = 50

  // let [allImages, setAllImages] = useState(null)

  let currentX =
    Math.abs(props.distanceX) /
    (Math.abs(props.furthestCoordinates.minX) +
      Math.abs(props.furthestCoordinates.maxX))

  let currentXOriginal =
    props.distanceX /
    (Math.abs(props.furthestCoordinates.minX) +
      Math.abs(props.furthestCoordinates.maxX))

  let currentYOriginal =
    props.distanceY /
    (Math.abs(props.furthestCoordinates.minY) +
      Math.abs(props.furthestCoordinates.maxY))

  let currentY =
    Math.abs(props.distanceY) /
    (Math.abs(props.furthestCoordinates.minY) +
      Math.abs(props.furthestCoordinates.maxY))

  let minRatioX =
    Math.abs(props.furthestCoordinates.minX) /
    (Math.abs(props.furthestCoordinates.minX) +
      Math.abs(props.furthestCoordinates.maxX))

  let maxRatioX =
    Math.abs(props.furthestCoordinates.maxX) /
    (Math.abs(props.furthestCoordinates.minX) +
      Math.abs(props.furthestCoordinates.maxX))

  let minRatioY =
    Math.abs(props.furthestCoordinates.minY) /
    (Math.abs(props.furthestCoordinates.minY) +
      Math.abs(props.furthestCoordinates.maxY))

  let maxRatioY =
    Math.abs(props.furthestCoordinates.maxY) /
    (Math.abs(props.furthestCoordinates.minY) +
      Math.abs(props.furthestCoordinates.maxY))

  let locationX =
    currentXOriginal > 0 ? 1 - maxRatioX + currentX : minRatioX - currentX

  let locationY =
    currentYOriginal > 0 ? 1 - maxRatioY + currentY : minRatioY - currentY

  let removeWidth = currentXOriginal > 0 ? 5 : 0

  let removeHeight = currentYOriginal > 0 ? 5 : 0

  let x = locationX * width - removeWidth

  let y = locationY * height - removeHeight

  // const imagePlacement = item => {

  //   return (
  //     <Item
  //       style={{
  //         left: `${x}px`,
  //         top: `${y}px`,
  //         height: `${adjustedHeight}px`,
  //         width: `${adjustedWidth}px`,
  //       }}
  //     />
  //   )
  // }

  // useEffect(() => {
  //   setAllImages(props.allImages.map(item => imagePlacement(item)))
  // }, [])

  return (
    <>
      <Container className={props.dragging ? "show-overview" : "hide-overview"}>
        {/* <Background
          className={props.touched ? "touch-overview" : "notouch-overview"}
        /> */}
        <LocationIndicator style={{ left: `${x}px`, top: `${y}px` }} />
        {/* {allImages} */}
      </Container>
    </>
  )
  //   }
}

export default OverviewGrid
