import React from "react"
import styled from "styled-components"

import posed from "react-pose"

import Img from "gatsby-image"

import gsap, { Sine, Power3, Expo } from "gsap"

import OverviewGrid from "./overview-grid"

import ScotomaLogoImg from "../images/scotoma.svg"

const Container = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  cursor: grab;
  opacity: 0;
  z-index: -1;
  pointer-events: all;

  :active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`

const ImageContainer = styled.div`
  position: absolute;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  transform: translate(
    ${props => props.translateX}px,
    ${props => props.translateY}px
  );
  z-index: -1;
  user-select: none;
  border-radius: 10px;
  overflow: hidden;

  .gatsby-image-wrapper {
    transition: transform 1s;
  }

  transition: filter 0.3s;

  .scotoma-watermark {
    opacity: 0;
    transition: opacity 0.3s;
  }

  :hover .gatsby-image-wrapper {
    transform: scale(1.05);
    transition: transform 2s;
  }

  :active {
    pointer-events: none;
  }

  :hover .scotoma-watermark {
    opacity: 0.7;
    transition: opacity 0.5s;
  }
`

const StyledImg = styled(Img)``

const Background = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
`

const ScotomaLogo = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  background: rgb(0, 0, 0, 0.3);

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100px;
    width: 100px;
  }
`

const preDragIndicator = posed.div({
  enter: {
    opacity: 1,
    filter: "blur(0px)",
  },
  exit: {
    opacity: 0,
    filter: "blur(20px)",
  },
})

const DragIndicator = styled(preDragIndicator)`
  position: fixed;
  font-family: "Univers Standard";
  font-size: 5rem;
  color: rgb(255, 255, 255, 0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  user-select: none;
  letter-spacing: -0.1rem;
  transition-duration: opacity 1s;
`

class Grid extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      images: [],
      viewPortSize: null,
      hasTouched: false,
      hasDragged: false,
      showOverviewGrid: false,
    }

    this.imagesRefs = []

    this.imagesContainer = null

    this.randomImagePositions = []

    this.imagesSrc = this.props.data.images

    this.images = null

    this.widthAllImages = 0
    this.heightAllImages = 0

    this.multiplier = 0.2

    //Drag Variables

    this.lastX = 0
    this.lastY = 0
    this.dragging = false

    this.furthestCoordinates = { maxX: 0, maxY: 0, minX: 0, minY: 0 }

    this.distanceX = 0
    this.distanceY = 0
  }

  componentDidMount() {
    this.setState({
      images: this.getImageSizes(),
      viewPortSize: this.getViewportSize(),
    })

    setTimeout(() => {
      this.setRandomPositions()
      this.getFurthestCoordinates()

      this.state.images.forEach((item, index) => {
        // TweenMax.to(this.imagesRefs[index], 0.75, {
        //   x: item.x,
        //   y: item.y
        // });
        this.imagesRefs[
          index
        ].style.transform = `translate(${item.x}px,${item.y}px)`
      })

      this.setState({
        showOverviewGrid: true,
      })
    }, 0)

    setTimeout(() => {
      this.containerRef.style.transition = "opacity 1s"
      this.containerRef.style.opacity = 1
    }, 200)
  }

  getViewportSize = () => {
    let height = window.innerHeight
    let width = window.innerWidth

    return {
      vheight: height,
      vwidth: width,
    }
  }

  getImageSizes = () => {
    let images = this.imagesSrc
    let imagesArray = []

    // Get Images Height & Width
    images.forEach(image => {
      let img = {
        src: image.image.localFile.childImageSharp.fluid,
        height: image.image.dimensions.height * this.multiplier,
        width: image.image.dimensions.width * this.multiplier,
        x: 0,
        y: 0,
      }
      this.heightAllImages = this.heightAllImages + img.height
      this.widthAllImages = this.widthAllImages + img.width
      imagesArray.push(img)
    })

    this.heightAllImages = (this.heightAllImages * this.multiplier) / 3
    this.widthAllImages = (this.widthAllImages * this.multiplier) / 3

    return imagesArray
  }

  removeOverlap = () => {}

  setRandomPositions = () => {
    let images = this.state.images.slice()
    images.forEach((item, index) => {
      let randomPosition = this.randomPosition(index)
      images[index].x = randomPosition.x
      images[index].y = randomPosition.y

      this.setState({
        images: images,
      })
    })
  }

  randomPosition = index => {
    let randomPositionX
    let randomPositionY
    let boundary = 200
    // let minBoundaryX = -boundary
    // let maxBoundaryX = this.state.viewPortSize.vwidth + boundary
    // let minBoundaryY = -boundary
    // let maxBoundaryY = this.state.viewPortSize.vheight + boundary
    let randomNegativeX
    let randomNegativeY
    let imagePositionObject = { x: 0, y: 0 }
    let isOverlap = true
    let count = 0

    //Get Random Position For X Axis

    while (
      // randomPositionX > maxBoundaryX ||
      // randomPositionX < minBoundaryX ||
      isOverlap === true
    ) {
      randomNegativeX = Math.random() > 0.5 ? true : false
      randomNegativeY = Math.random() > 0.5 ? true : false
      // if (index !== 0) {
      randomPositionX = randomNegativeX
        ? Math.floor(-Math.random() * this.widthAllImages)
        : Math.floor(
            Math.random() *
              (this.widthAllImages + this.state.viewPortSize.vwidth)
          )

      randomPositionY = randomNegativeY
        ? Math.floor(-Math.random() * this.heightAllImages)
        : Math.floor(
            Math.random() *
              (this.heightAllImages + this.state.viewPortSize.vheight)
          )
      // } else {
      //   randomPositionX = this.state.viewPortSize.vwidth / 2

      //   randomPositionY = this.state.viewPortSize.vheight / 2
      // }

      imagePositionObject.x = randomPositionX

      imagePositionObject.y = randomPositionY

      isOverlap = this.isOverlap(index, imagePositionObject)

      if (isOverlap === false || count > 999) {
        break
      }

      count++
    }

    this.randomImagePositions.push(imagePositionObject)

    //Add Random Position To imagePositions Array

    return imagePositionObject
  }

  isOverlap = (index, imagePositionObject) => {
    // if (index === 0) return;
    let images = this.state.images
    let isOverlap = false

    for (let i = 0; i < images.length; i++) {
      let noOverlapX =
        imagePositionObject.x > images[i].x + images[i].width ||
        imagePositionObject.x + images[index].width < images[i].x

      // let proximityX =
      //   imagePositionObject.x < images[i].x + images[i].width ||
      //   imagePositionObject.x + images[index].width >
      //     images[i].x - images[index].width

      let noOverlapY =
        imagePositionObject.y > images[i].y + images[i].height ||
        imagePositionObject.y + images[index].height < images[i].y

      // let proximityY =
      //   imagePositionObject.y < images[i].y + images[i].height ||
      //   imagePositionObject.y + images[index].height >
      //     images[i].y - images[index].height

      if (
        !noOverlapX &&
        !noOverlapY
        //  && proximityX && proximityY
      ) {
        isOverlap = true
      }
    }

    return isOverlap
  }

  getFurthestCoordinates = () => {
    let images = this.state.images.slice()
    let furthestCoordinates = { maxX: 0, maxY: 0, minX: 0, minY: 0 }
    let yCoordinates = []
    let xCoordinates = []

    xCoordinates = images.map(item => {
      return item.x
    })

    yCoordinates = images.map(item => {
      return item.y
    })

    let maxXWidth = images.filter((item, index) => {
      return item.x === Math.max(...xCoordinates)
    })

    let maxYHeight = images.filter((item, index) => {
      return item.y === Math.max(...yCoordinates)
    })

    furthestCoordinates.maxX =
      Math.max(...xCoordinates) -
      this.state.viewPortSize.vwidth +
      maxXWidth[0].width +
      150
    furthestCoordinates.maxY =
      Math.max(...yCoordinates) -
      this.state.viewPortSize.vheight +
      maxYHeight[0].height +
      150
    // furthestCoordinates.maxX = this.widthAllImages + maxXWidth[0].width
    // furthestCoordinates.maxY = this.heightAllImages + maxYHeight[0].height

    furthestCoordinates.minX = Math.min(...xCoordinates)
    furthestCoordinates.minY = Math.min(...yCoordinates)

    this.furthestCoordinates = furthestCoordinates
  }

  onDrag = e => {}

  onStart = e => {
    e = e.type == "touchstart" ? e.touches[0] : e
    this.lastX = e.clientX
    this.lastY = e.clientY
    this.dragging = true
    this.setState({
      dragging: true,
      hasDragged: true,
    })
  }

  onEnd = e => {
    this.dragging = false
    this.setState({
      dragging: false,
    })
  }

  // getMatrix = element => {
  //   const values = element.style.transform.split(/\w+\(|\);?/)
  //   const transform = values[1].split(/,\s?/g).map(item => {
  //     return parseInt(item)
  //   })

  //   return {
  //     x: transform[0],
  //     y: transform[1],
  //     z: transform[2],
  //   }
  // }

  onMove = e => {
    if (this.dragging) {
      e = e.type == "touchmove" ? e.touches[0] : e
      let xDelta = (e.clientX - this.lastX) * 2
      let yDelta = (e.clientY - this.lastY) * 2

      let stopX = false
      let stopY = false

      let prevImageArrays = this.state.images.slice()

      if (
        this.distanceX <= this.furthestCoordinates.minX ||
        this.distanceX >= this.furthestCoordinates.maxX
      )
        stopX = true

      if (
        this.distanceY <= this.furthestCoordinates.minY ||
        this.distanceY >= this.furthestCoordinates.maxY
      )
        stopY = true

      // let timeline = gsap.timeline()

      // timeline.staggerTo(
      //   this.imagesContainer.children,
      //   0.8,
      //   {
      //     x: index => {
      //       let coordinates = this.getMatrix(
      //         this.imagesContainer.children[index]
      //       )

      //       return coordinates.x + xDelta
      //     },
      //     y: index => {
      //       let coordinates = this.getMatrix(
      //         this.imagesContainer.children[index]
      //       )
      //       return coordinates.y + yDelta
      //     },
      //     ease: "power3.out",
      //   },
      //   0.003,
      //   null
      // )

      prevImageArrays.map((item, index) => {
        gsap.to(this.imagesRefs[index], {
          duration: 1,
          // ease: Power3.easeOut,
          x: item.x + xDelta,
          y: item.y + yDelta,
        })

        item.x = stopX ? item.x : item.x + xDelta
        item.y = stopY ? item.y : item.y + yDelta

        return item.x, item.y
      })

      //Set Total Distance Moved
      this.distanceX = this.distanceX - xDelta
      this.distanceY = this.distanceY - yDelta

      //Set Last Mouse Position
      this.lastX = e.clientX
      this.lastY = e.clientY

      //Stop Tracking Mouse When Reached Edge
      if (this.distanceX < this.furthestCoordinates.minX) {
        this.distanceX = this.furthestCoordinates.minX
        this.triggerTouchLimit()
      }

      if (this.distanceX > this.furthestCoordinates.maxX) {
        this.distanceX = this.furthestCoordinates.maxX
        this.triggerTouchLimit()
      }

      if (this.distanceY < this.furthestCoordinates.minY) {
        this.distanceY = this.furthestCoordinates.minY
        this.triggerTouchLimit()
      }

      if (this.distanceY > this.furthestCoordinates.maxY) {
        this.distanceY = this.furthestCoordinates.maxY
        this.triggerTouchLimit()
      }

      this.setState({
        images: prevImageArrays,
      })
    }
  }

  triggerTouchLimit = () => {
    this.setState({
      hasTouched: true,
    })

    setTimeout(() => {
      this.setState({
        hasTouched: false,
      })
    }, 200)
  }

  render() {
    return (
      <Container
        onMouseDown={this.onStart}
        onMouseMove={this.onMove}
        onMouseUp={this.onEnd}
        ref={el => (this.containerRef = el)}
      >
        <DragIndicator pose={!this.state.hasDragged ? "enter" : "exit"}>
          Drag to browse
        </DragIndicator>
        <Background className={this.state.hasTouched ? "touch" : "notouch"} />
        {this.state.showOverviewGrid && (
          <OverviewGrid
            furthestCoordinates={this.furthestCoordinates}
            distanceX={this.distanceX}
            distanceY={this.distanceY}
            allImages={this.state.images}
            touched={this.state.hasTouched}
            dragging={this.state.dragging}
          />
        )}
        <div ref={el => (this.imagesContainer = el)}>
          {this.state.images.map((image, index) => (
            <ImageContainer
              ref={el => (this.imagesRefs[index] = el)}
              key={index}
              height={image.height}
              width={image.width}
            >
              {/* <ScotomaLogo className="scotoma-watermark">
                <img src={ScotomaLogoImg} />
              </ScotomaLogo> */}
              <StyledImg fluid={image.src} />
            </ImageContainer>
          ))}
        </div>
      </Container>
    )
  }
}

export default Grid
